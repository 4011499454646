/**
 * 资源状态文本数组
 */
export const ResourceTypeText = [
    {
        label: '正常',
        value: 0,
    },
    {
        label: '异常',
        value: 1,
    }
];
/**
 * 商品状态文本数组
 */
export const GoodsTypeText = [
    {
        label: '已上架',
        value: true,
    },
    {
        label: '已下架',
        value: false,
    }
];

/**
 * 来源渠道数组
 */
export const sourceChannelText = [
    {
        label: '广州子轩',
        value: 'GZZX',
    }
];
/**
 * 资源类型数组
 */
export const SourcetypeText = [
    {
        label: '直充',
        value: 'DIRECT_CHARGE',
    }
];
export const _videoResources = {
    //筛选
    search: [
        {
            type: 'el-input',
            label: '资源名称：',
            value: 'sourceName',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '资源类型：',
            value: 'sourceFrom ',
            props: {
                placeholder: '请输入',
                clearable: true
            },
            options: [
                {
                    label: '广州子轩',
                    value: 'GZZX'
                }
            ]
        },
        {
            type: 'el-input',
            label: '第三方资源编号ID：',
            value: 'thirdSourceNo',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-select',
            label: '资源状态：',
            value: 'abnormal',
            props: {
                filterable: true,
                clearable: true
            },
            options: ResourceTypeText
        },
        {
            type: 'el-select',
            label: '商品状态：',
            value: 'listing',
            props: {
                filterable: true,
                clearable: true
            },
            options: GoodsTypeText
        },
    ],
    //列表
    table: [
        {
            type: 'index',
            label: '序号',
            width: '50'
        },
        {
            prop: 'sourceName',
            label: '资源名称',
        },
        {
            prop: '_sendType',
            label: '发放类型'
        },
        {
            prop: '_sourceFrom',
            label: '来源渠道',
        },
        {
            prop: 'thirdSourceName',
            label: '第三方资源名称',
        },
        {
            prop: 'thirdSourceNo',
            label: '第三方资源id',
        },
        {
            label: '资源图片',
            self: {
                body: 'sourceImg'
            }
        },
        {
            label: '资源领取规则',
            self: {
                body: 'sourceRemark'
            }
        },
        {
            prop: '_salePrice',
            label: '销售价',
        },
        {
            prop: '_purchasePrice',
            label: '渠道成本价',
        },
        {
            prop: 'sourceUnit',
            label: '资源单位',
        },
        {
            prop: '_abnormal',
            label: '资源状态',
        },
        {
            prop: '_listing',
            label: '商品状态',
        },
        {
            prop: '_createTime',
            label: '创建时间'
        },
        {
            label: '操作',
            width: 250,
            self: {
                body: 'operation'
            }
        }
    ],
    //规则
    rules: {
        sourceName: [{ required: true, message: '请输入资源名称', trigger: 'blur' }],
        sourceFrom: [{ required: true, message: '请选择来源渠道', trigger: 'blur' }],
        sendType: [{ required: true, message: '请选择发放类型', trigger: 'blur' }],
        thirdSourceNo: [{ required: true, message: '请选择第三方资源', trigger: 'blur' }],
        sourceImg: [{ required: true, message: '请上传资源图片', trigger: 'blur' }],
        sourceRemark: [{ required: true, message: '请输入资源领取规则', trigger: 'blur' }],
        salePrice: [{ required: true, message: '请输入资源销售价格', trigger: 'blur' }],
        sourceUnit: [{ required: true, message: '请输入资源单位', trigger: 'blur' }],
    },
};
