
import { Component, Vue } from "vue-property-decorator";
import {
    _videoResources,
    sourceChannelText,
    SourcetypeText,
} from "@/utils/pageConfig/videovips/videoResources";
import { Foundation } from "@/utils/Foundation";
import API_FinanceManage from "@/utils/API/finance/FinanceManage";
import addVideo from "@/views/videovips/components/addVideo.vue";
import VideoApi from "@/utils/API/videovip/videoApi";

@Component({
    components: { addVideo },
})
export default class FinanceManage extends Vue {
    /*表格相关*/
    pageConfig: any = _videoResources;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };

    mounted() {
        this.getTableData();
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await VideoApi.resourceQuery(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._salePrice = Foundation.toYUAN(e.salePrice);
                    e._purchasePrice = Foundation.toYUAN(e.purchasePrice);
                    e._listing = e.listing ? "已上架" : "已下架";
                    e._abnormal = e.abnormal ? "异常" : "正常";
                    e._sendType = Foundation.findInArray(
                        SourcetypeText,
                        "value",
                        e.sendTypeEm
                    ).label;
                    e._sourceFrom = Foundation.findInArray(
                        sourceChannelText,
                        "value",
                        e.sourceFromEm
                    ).label;
                    e._createTime = Foundation.dateFormat(e.createTime);
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        query = Foundation.resetDate(query);
        this.params = {
            current: 1,
            size: 20,
            ...query,
        };
        this.getTableData();
    }

    /*编辑*/
    handleEdit(data: any) {
        (this.$refs.addvideo as any).show(data);
    }

    /* 上架/下架 */
    handleShow(row: any) {
        Foundation.beforeDelete(
            () => {
                VideoApi.listing({ id: row.id, listing: !row.listing }).then(
                    (res) => {
                        this.$message.success("操作成功");
                        this.getTableData();
                    }
                );
            },
            "确认要执行此操作吗",
            "操作取消"
        );
    }

    /* 资源--异常/正常 */
    handleabnormal(row: any) {
        Foundation.beforeDelete(
            () => {
                VideoApi.abnormal({ id: row.id, abnormal: !row.abnormal }).then(
                    (res) => {
                        this.$message.success("操作成功");
                        this.getTableData();
                    }
                );
            },
            "确认要执行此操作吗",
            "操作取消"
        );
    }

    /* 自动更新采购价格 */
    handleupdate(row: any) {
        VideoApi.purchaseprice({ id: row.id }).then((res) => {
            this.$message.success("操作成功");
            this.getTableData();
        });
    }

    /*新增*/
    handleAdd() {
        (this.$refs.addvideo as any).show();
    }
}
